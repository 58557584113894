import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from 'reactstrap'
import Subscription from '../components/subscription-cta'
import { StaticImage } from 'gatsby-plugin-image'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/index.scss'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="DDSLocator | Find a Dentist Near Me"
        description="How can I find a dentist near me? DDSLocator provides a searchable database of licensed local dentists across the United States and Canada."
      />
      <Container fluid={true}>
        <Row>
          <Col md="12">
            <div className="main-image mt-4">
              <StaticImage
                src="../images/DDSLocator-header-2500x651.jpg"
                alt="Dentists"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid={true} className="mt-4">
        <Row>
          <Col lg="12">
            <div className="blue-box">
              <h1 className="text-center main-title mt-4 mb-5 pt-3">
                How do I find the right dentist near me?
              </h1>
              <div className="btn-container pb-5">
                <a href="/find-dentist" className="dentist-btn mb-5">
                  Start Here
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid={true} className="mt-4">
        <Row>
          <Col lg="12">
            <div className="text-center purple-box">
              <p>
                How do I find the right dentist near me? It’s a common question.
                As a dental patient, not only are you looking for a local
                dentist but the best dentist to treat your unique oral health
                care situation. DDSLocator provides a searchable database of
                licensed local dentists across the United States and Canada.
                Find dentists in your area, learn about their specialties and
                services and read reviews from patients. When you find the right
                dentist it’s easy to contact them to ask a question or set up an
                appointment.
              </p>
            </div>
            <Subscription></Subscription>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
